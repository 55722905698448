//
// Variables
//

// Reboot
$app-bg-color: $body-bg;
$app-bg-color-dark: $body-bg-dark; 

// General
$app-general-root-font-size-desktop: 13px;
$app-general-root-font-size-tablet: 12px;
$app-general-root-font-size-mobile: 12px;

// Container
$app-container-padding-x: 40px !default;
$app-container-padding-x-mobile: 20px !default;

// Content
$app-content-padding-y: 30px;
$app-content-padding-y-mobile: 20px;

// Header base
$app-header-base-height: 110px;
$app-header-base-height-mobile: 60px;
$app-header-base-bg-color: $white;
$app-header-base-bg-color-dark: $gray-100-dark;
$app-header-base-border-bottom: 1px solid $gray-200; 
$app-header-base-border-bottom-dark: 1px solid $gray-200-dark;

// Header sticky
$app-header-sticky-height: 80px;
$app-header-sticky-bg-color: $app-header-base-bg-color;
$app-header-sticky-bg-color-dark: $app-header-base-bg-color-dark;
$app-header-sticky-border-bottom: 1px solid $gray-200; 
$app-header-sticky-border-bottom-dark: 1px solid $gray-200-dark;

// Toolbar base
$app-toolbar-base-height: auto;
$app-toolbar-base-height-mobile: auto;
$app-toolbar-base-border-bottom: 1px solid $gray-200; 
$app-toolbar-base-border-bottom-dark: 1px solid $gray-200-dark;

// Sidebar base
$app-sidebar-base-width: 340px;
$app-sidebar-base-bg-color: #131313;
$app-sidebar-base-bg-color-dark: #15151e;
$app-sidebar-base-box-shadow: none;
$app-sidebar-base-box-shadow-dark: none;

$app-sidebar-base-menu-link-title: #9BA0B8;
$app-sidebar-base-menu-link-icon: #C5C8D4;
$app-sidebar-base-menu-link-icon-dark: $white;
$app-sidebar-base-menu-link-active: #1E1F27; 

// Footer
$app-footer-height: auto;
$app-footer-height-mobile: auto;

// Scrolltop
$scrolltop-bottom: 43px;
$scrolltop-bottom-mobile: 23px;
$scrolltop-end: 7px;
$scrolltop-end-mobile: 5px;

// Layout builder toggle(used for Demo purposes only)
$app-layout-builder-toggle-bg-color: $body-bg;
$app-layout-builder-toggle-end: 50px;
$app-layout-builder-toggle-end-mobile: 40px;
$app-layout-builder-toggle-bottom: 40px;
$app-layout-builder-toggle-bottom-mobile: 20px;